import { Platform, Pressable, StyleSheet, View } from "react-native";
import React, { useState, useEffect } from "react";
import RunnerSilkAndNumbers from "./RunnerSilkAndNumbers";
import variables from "../../../../../styles/variables";
import RunnerDetails from "../../../../Racecard/RunnerDetails";
import Gap from "../../../../Gap";
import RText from "../../../../RText";
import StandardPriceButton from "../../../../Buttons/StandardPriceButton";
import Icon from "../../../../Icon/Icon";
import TimeformStarRating from "./TimeformStarRating";
import ChevronIcon from "../../../../ChevronIcon/ChevronIcon";
import { useResponsive } from "../../../../../hooks/useResponsive";
import AnimatedDropdownWrapper from "../../../../Animations/DropdownAnimation";
import ExpandedRacecardRunnerRow from "./ExpandedRacecardRunnerRow";
import { Runner } from "../../../../../types/races/runner";
import { useAtom } from "jotai";
import { userAtom } from "../../../../../atoms";
import { bestOddsForBookmaker } from "../../../../../client/odds";
import { PriceFormat } from "../../../../../types/tipster";
import { OddsFilterOptionValue } from "../../../../Tabs/Sorter";
import { formatEquipmentText } from "../../../../../utils/race";
import Race from "../../../../../types/races/race";
import { OddBookmakers } from "../../../../../types/bookmaker";
import { HorseTrackerType } from "../../../../../types/races/horse";

type Props = {
  race: Race;
  runner: Runner;
  allExpanded?: boolean;
  priceFormat?: PriceFormat;
  oddsFilterOptionValue?: OddsFilterOptionValue;
  lastRunner?: boolean;
  nonRunner?: boolean;
  displaysPremiumData: boolean;
  bookmakers?: OddBookmakers;
  trackedHorse?: HorseTrackerType;
};

const RacecardRunnerRow = ({
  race,
  runner,
  allExpanded,
  priceFormat,
  oddsFilterOptionValue,
  lastRunner,
  nonRunner,
  displaysPremiumData,
  bookmakers,
  trackedHorse,
}: Props) => {
  const { isSmallAndDown } = useResponsive();
  const [user] = useAtom(userAtom);
  const [isExpanded, setIsExpanded] = useState(false);
  const userSelectedBookmaker = user?.member?.bookmaker_display?.bookmaker_id;

  let oddsToUse;
  if (userSelectedBookmaker && oddsFilterOptionValue == "preferred-bookmaker") {
    oddsToUse = bestOddsForBookmaker(runner.odds, userSelectedBookmaker);
  } else {
    oddsToUse = runner.bestOdds;
  }

  return (
    <View
      style={[
        styles.container,
        lastRunner ? styles.noBorder : styles.bottomBorder,
        nonRunner ? styles.nonRunner : undefined,
      ]}
    >
      <View style={{ flexDirection: "row" }}>
        <RunnerSilkAndNumbers
          status={runner.status}
          form={runner.form}
          silk={runner.silk}
          clothNumber={runner.cloth_number}
          barrierNumber={runner.barrier_number}
        />
        <Gap />
        <RunnerDetails
          runner={runner}
          displaysPremiumData={displaysPremiumData}
          trainerBadges={runner?.trainer_icon_badges}
          jockeyBadges={runner?.jockey_icon_badges}
        />
        {Platform.OS !== "android" && <Gap />}
        {isSmallAndDown ? (
          <View
            style={{
              flex: 1,
              justifyContent: "space-around",
              flexDirection: "column",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                minHeight: 25,
              }}
            >
              <RText>{runner.age}</RText>
              <Gap size="xxxsmall" />
              <RText>{runner.format_weight}</RText>
              <Gap size="xxxsmall" />
              {formatEquipmentText(runner.equipment_text)}
              <Gap size="xxxsmall" />
              <TimeformStarRating
                displaysPremiumData={displaysPremiumData}
                starRating={runner.timeform_star_rating}
                formattedRating={runner.format_timeform_rating}
              />
            </View>

            <View
              style={{
                alignItems: "center",
                justifyContent: "flex-end",
                flexDirection: "row",
                paddingTop: 4,
              }}
            >
              {oddsToUse ? (
                <StandardPriceButton
                  odd={oddsToUse}
                  runner={runner}
                  race={race}
                  priceFormat={priceFormat}
                  bookmaker={bookmakers?.[oddsToUse.bookmaker_id] || null}
                />
              ) : (
                <View style={{ width: 80, backgroundColor: "transparent" }} />
              )}
            </View>
            <Pressable
              onPress={() => setIsExpanded((prev) => !prev)}
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flex: 1,
                  alignSelf: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <ChevronIcon type={isExpanded ? "up" : "down"} />
              </View>
            </Pressable>

            <Gap size="xxsmall" />
          </View>
        ) : (
          <>
            <View style={styles.center}>
              <RText>{runner.horse?.age || runner.age}</RText>
            </View>
            <Gap />
            <View style={styles.center}>
              <RText>{runner.format_weight}</RText>
              {formatEquipmentText(runner.equipment_text)}
            </View>
            <Gap />
            <View style={[styles.center]}>
              <RText>
                OR{" "}
                {runner.timeform_original_rating == 0
                  ? "-"
                  : runner.timeform_original_rating}
              </RText>
            </View>
            <Gap />
            <View style={styles.center}>
              <TimeformStarRating
                displaysPremiumData={displaysPremiumData}
                starRating={runner.timeform_star_rating}
                formattedRating={runner.format_timeform_rating}
              />
              <Pressable onPress={() => setIsExpanded((prev) => !prev)}>
                <ChevronIcon type={isExpanded ? "up" : "down"} />
              </Pressable>
            </View>
            <Gap />
            <View>
              {oddsToUse ? (
                <StandardPriceButton
                  odd={oddsToUse}
                  race={race}
                  runner={runner}
                  priceFormat={priceFormat}
                  bookmaker={bookmakers?.[oddsToUse.bookmaker_id] || null}
                />
              ) : (
                <View style={{ width: 95, backgroundColor: "transparent" }} />
              )}
            </View>
            <Gap size="xxsmall" />
          </>
        )}
      </View>
      <AnimatedDropdownWrapper
        open={isExpanded || allExpanded}
        alwaysMountedOnWeb
      >
        <ExpandedRacecardRunnerRow
          isExpanded={isExpanded || allExpanded}
          priceFormat={priceFormat}
          displaysPremiumData={displaysPremiumData}
          runner={runner}
          bookmakers={bookmakers}
          trackedHorse={trackedHorse}
        />
      </AnimatedDropdownWrapper>
    </View>
  );
};

export default RacecardRunnerRow;

const { spacing, colors } = variables;

const styles = StyleSheet.create({
  container: {
    paddingVertical: spacing.xxsmall,
    minWidth: "100%",
    maxWidth: "100%",
  },
  noBorder: {
    borderBottomWidth: 0,
  },
  bottomBorder: {
    borderBottomColor: colors.palette.neutral.x100,
    borderBottomWidth: 1,
  },
  center: {
    flex: 1,
    alignItems: "center",
  },
  nonRunner: {
    opacity: 0.5,
  },
});
