import { View } from "react-native";
import React, { FC, useEffect } from "react";
import { MEMBERS_APP_URL } from "../../client";
import { getCurrentRouteName, saveJwtToken } from "./Utils";
import { useAtom, useSetAtom } from "jotai";
import { signupPageNumberAtom, userAtom } from "../../atoms";
import ScrollDownIcon from "./ScrollDownIcon";
import useRefreshUser from "../../hooks/useRefreshUser";
import { useToast } from "react-native-toast-notifications";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../types/route";
import { logEvent } from "../../client/analytics/trackPageView";

type RWebViewProps = {
  source: string;
  // type?: "login" | "join";
  // style?: any;
  closeModal?: any;
  scrollEnabled?: boolean;
  heightOverride?: number;
  widthOverride?: number | string;
};

const cacheBustMinute = Math.floor(Date.now() / 1000 / 60);

const RWebView: FC<RWebViewProps> = ({
  source,
  closeModal,
  scrollEnabled,
  heightOverride,
  widthOverride,
}) => {
  const [user, setUser] = useAtom(userAtom);
  const [showScrollDown, setShowScrollDown] = React.useState(false);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const { refreshUser } = useRefreshUser();
  const toast = useToast();
  const setSignupPageNumber = useSetAtom(signupPageNumberAtom);

  useEffect(() => {
    const handleMessage = async (event) => {
      if (event.data === "clubday-application-success") {
        closeModal();
        toast.show("Application submitted!", {
          type: "success",
          placement: "bottom",
          duration: 5000,
          animationType: "slide-in",
        });
        return;
      } else if (event.data === "clubday-application-failure") {
        closeModal();
        toast.show("Application failed to submit!", {
          type: "danger",
          placement: "bottom",
          duration: 5000,
          animationType: "slide-in",
        });
        return;
      }

      if (event.origin !== MEMBERS_APP_URL) return;
      console.log("*** eventOrigin: " + event.origin);

      const { data } = event;
      console.log("got event:: ", data);
      if (data.action === "background-login") {
        await refreshUser(data.jwt_token);
        return;
      }

      if (data.signup_complete) {
        await refreshUser(data.jwt_token);

        if (getCurrentRouteName(navigation) === "Watch") {
          closeModal();
        } else {
          navigation.navigate("Watch");
          closeModal();
        }

        return; // don't do anything else
      }

      if (data.navigate) {
        // no need to close modal, opens in new tab
        if (data.externalUrl) {
          window?.open(data.externalUrl, "_blank");
        } else {
          window?.open(data.internalUrl, "_blank");
        }
      }

      if (data === "close") closeModal();
      if (data === "scrolled_to_bottom:false")
        !showScrollDown && setShowScrollDown(true);

      if (data.info) {
        if (data.info?.currentSignupPage) {
          setSignupPageNumber(data.info.currentSignupPage);
        }
      }

      if (data.logEvent) {
        logEvent({
          key: data.logEvent.key,
          data: data.logEvent.data,
        });
      }
      if (data === "scrolled_to_bottom:true")
        showScrollDown && setShowScrollDown(false);
      if (data.jwt_token) {
        saveJwtToken(data.jwt_token)
          .then(() => setUser(data))
          .then(() => closeModal())
          .then(() => {
            console.log("***** B user in RWebView: " + JSON.stringify(user));
            window.location.reload();
          })
          .catch(() => console.log("Error saving token"));
      }
    };

    window.addEventListener("message", handleMessage, false);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, [navigation, refreshUser, toast, closeModal]); // Include dependencies here

  return (
    <View style={{ height: "100%", width: "100%", position: "relative" }}>
      <iframe
        id="iframe"
        onLoad={(e) => {
          //will match height of modal until scroll
          // const iframeHeight = document.getElementById("iframe").scrollHeight;
          // console.log("iframeHeight: " + iframeHeight);
        }}
        style={{
          height: heightOverride ? heightOverride : "100%",
          width: widthOverride ? widthOverride : "100%",
          border: "none",
          borderRadius: 10,
        }}
        src={
          source +
          `${
            source.includes("?")
              ? `&v=${cacheBustMinute}`
              : `?v=${cacheBustMinute}`
          }`
        }
        scrollEnabled={scrollEnabled}
      />
      {showScrollDown && <ScrollDownIcon />}
    </View>
  );
};

export default RWebView;
